#categories {
  height: max-content;
  width: 100%;
  background-color: black;
}

#categories .title {
  color: white;
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 0.01em;
  margin-bottom: 125px;
}

#categories .image {
  margin-bottom: 40px;
  height: 66px;
  width: auto;
}

#categories .heading {
  margin-bottom: 30px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

#categories .text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  opacity: 0.5;
  width: 100%;
  max-width: 400px;
}

#categories .column {
  margin-bottom: 123px;
}

/* #categories .flare.one {
  position: absolute;
  left: -37%;
  top: -568px;
}

#categories .flare.one {
  position: absolute;
  left: -37%;
  top: -568px;
} */

@media (max-width: 768px) {
  #categories .column {
    margin-bottom: 32px;
  }

  #categories .title {
    margin-bottom: 70px;
  }

  #categories .flare.two {
    top: 1025px !important;
  }

  #categories .text {
    font-weight: 300;
  }
}
