#navigation-bar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 7;
  background: rgba(0, 0, 0, 0.6);
}

#navigation-bar .navbar-row {
  height: 72px;
}

#navigation-bar .navbar-col {
  display: flex;
  align-items: center;
  color: white !important;
  opacity: 1;
}

#navigation-bar .navbar-logo-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#navigation-bar .mobile-menu {
  /* width: 100%; */
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  position: relative;
  z-index: 2;
}

#navigation-bar .mobile-menu .logo {
  height: 100%;
  width: auto;
  margin-bottom: 0px;
}

#navigation-bar .mobile-menu .hamburger {
  width: 30px;
  height: 30px;
  background-image: url('../images/hamburger.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0px;
  margin: 0;
  border: none !important;
  border-radius: 0;
  background-color: transparent;
}

#navigation-bar .menu-list {
  display: none;
}

.menu-list.collapsed {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  padding-top: 72px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  /* background-color: rgba(94, 43, 43, 0.5); */
}

.anchor:hover {
  filter: brightness(0.9);
}

.navbar-col a {
  margin-right: 30px;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
  color: white;
  cursor: pointer;
}

.menu-list a {
  text-decoration: none;
  margin-bottom: 28px;
  color: white;
  font-size: 22px;
  font-weight: 500;
}

#navigation-bar select {
  color: white;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

#navigation-bar select option {
  background-color: transparent;
  color: black;
  padding: 10px;
  cursor: pointer;
}

#navigation-bar select option:first-child {
  margin-top: 50px;
}

#navigation-bar select:focus {
  border: none;
  outline: none;
}

@media (max-width: 768px) {
  #navigation-bar select {
    font-size: 22px;
  }
}
