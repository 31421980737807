#faq-section {
  width: 100%;
  height: max-content;
  padding-bottom: 353px;
  /* background: linear-gradient(to top, #1D1450 -4.46%, #000000 8.96%); */
  position: relative;
  z-index: 3;
}

#faq-section .title {
  font-weight: 300;
  font-size: 48px;
  line-height: 1.2;
  letter-spacing: 0.01em;
  margin-bottom: 100px;
}

#faq-section .title::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

#faq-section .answer {
  margin-bottom: 0px;
  color: white;
  opacity: 0.5;
}

#faq-section .accordion {
  background-color: transparent;
}

/* #faq-section .answer:last-child {
  margin-bottom: 0px;
} */

#faq-section .accordion-item .accordion-button {
  background-color: transparent;
  color: white;
  padding-bottom: 18px !important;
  padding: 0px;
  padding-top: 43px !important;
  border-bottom: 1px solid #5c44ec;
  z-index: 3;
  opacity: 0.8;
}

#faq-section .accordion-item {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border: none;
  z-index: 3;
}

#faq-section .accordion-header {
  z-index: 3;
  background-color: black;
}

#faq-section .accordion-body {
  background-color: black;
  padding: 0px;
  padding-bottom: 56px !important;
  padding-top: 60px !important;
  display: flex;
  border-bottom: 1px solid #5c44ec;
}

#faq-section .accordion-button {
  text-transform: capitalize;
  font-size: 20px;
  letter-spacing: 0.06em;
  font-weight: 400;
  transition: font-size 0.2s ease-out;
}

#faq-section .accordion-button:hover {
  opacity: 0.9;
}

#faq-section .accordion-button[aria-expanded='true'] {
  font-size: 22px;
  opacity: 1;
  border-color: transparent !important;
}

#faq-section .accordion-button:focus {
  box-shadow: none !important;
}

#faq-section .accordion-button::after {
  background-image: url('../../images/arrow-down.svg') !important;
}

.accordion-button:not(.collapsed)::after {
  transform: scale(1.5) rotate(180deg) translate(3px, 5px) !important;
}

#send-application {
  z-index: 6;
}

@media (max-width: 991px) {
  #faq-section .answer {
    margin-bottom: 25px;
  }

  #faq-section .accordion-body {
    padding-bottom: 71px !important;
    padding-top: 16px !important;
  }

  #faq-section .container-xl {
    padding: 0px;
  }

  #faq-section .accordion-item .accordion-button {
    padding: 35px 0px !important;
    align-items: center;
  }

  .accordion-button.collapsed::after {
    transform: translateY(5px);
  }
}

@media (max-width: 768px) {
  #faq-section {
    padding-top: 263px;
    padding-bottom: 203px;
  }

  #faq-section .title {
    margin-bottom: 79px;
  }
}

/* @media (max-width: 576px) {
} */
