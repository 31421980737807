#discover {
  background-color: black;
  /* background: linear-gradient(to top, #1D1450 -4.46%, #000000 8.96%); */
}

#discover h1 {
  color: #fff;
  font-size: 48px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0.01em;
  margin-bottom: 83px;
}

#discover .article {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 6px;
  z-index: 3;
}

#discover .article .article-title-container {
  width: 100%;
  min-height: 56px;
  margin-bottom: 30px;

  display: flex;
  align-items: flex-end;
}

#discover .article h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 0;
  vertical-align: bottom;
}

#discover .article .article-img-container {
  margin-bottom: 20px;
  isolation: isolate;
}

#discover .article .article-img {
  mix-blend-mode: color;
}

#discover .article .color-mask {
  width: 100%;
  height: 100%;
  position: relative;
  top: -100%;
  left: 0;
  z-index: 1;
}

#discover .article img {
  width: 100%;
  height: auto;
}

#discover .article p {
  color: #fafafc;
  font-size: 14px;
  line-height: 160%;
  font-weight: 400;
  letter-spacing: 0.02em;
  opacity: 0.5;
  margin-bottom: 40px;
}

#discover .article a {
  color: #5C44EC;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-decoration: none;
}

#discover .article a:hover {
  filter: brightness(1.3);
}

#articles {
  padding-bottom: 247px;
}

@media (max-width: 768px) {
  #articles {
    padding-bottom: 104px;
  }

  #discover h1 {
    margin-bottom: 79px;
  }

  #discover .article p {
    margin-bottom: 20px;
    font-weight: 300;
  }

  #discover .flare {
    top: -1173px !important;
    right: -1134px !important;
    bottom: unset;
    transform: scale(0.5);
  }
}

@media (max-width: 1200px) {
  #discover .article {
    margin-bottom: 85px;
  }
}