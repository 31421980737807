#backdrop {
  width: calc(100vw - 17px);
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  overflow: hidden;
  padding: 15px;
  z-index: 10;
}

@keyframes slideDown {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

#backdrop .content-box {
  padding: 81px 64px;
  min-width: 345px;
  width: 918px;
  height: max-content;
  background-color: #5c44ec;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  animation: 0.3s slideDown ease-out;
  z-index: 11;
}

#backdrop .content-box .logo {
  margin-bottom: 38px;
  width: 289px;
}

#backdrop .content-box .info {
  color: white;
  font-size: 28px;
  letter-spacing: 0.01em;
  text-align: start;
  line-height: 1.2;
  font-weight: 300;
  max-width: 100% !important;
  margin-bottom: 59px;
}

#backdrop .content-box .row {
  margin-bottom: 48px;
  width: 100%;
}

#backdrop .content-box .description {
  margin-bottom: 18px;
  font-weight: 300;
  letter-spacing: 0.01em;
  color: white;
  font-size: 16px;
  text-align: left !important;
}

#backdrop .numbers {
  font-size: 16px;
  font-weight: 300;
  color: white;
  letter-spacing: 0.04em;
  margin-bottom: 0px;
  text-align: left !important;
}

#backdrop .buttons-wrapper {
  display: flex;
  flex-wrap: wrap-reverse;
}

#backdrop .buttons-wrapper a {
  text-decoration: none;
}

#backdrop .content-box .button {
  border-radius: 0px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  text-transform: uppercase;
  width: 229px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-weight: 700;
  letter-spacing: 0.01em;
  font-size: 16px;
  margin-right: 16px;
  /* max-width: 230px; */
  transition: background-color 0.3s ease;
}

#backdrop .content-box .exit {
  position: absolute;
  right: 33px;
  top: 33px;
  height: 24px;
  width: 24px;
  z-index: 15;
  transition: transform 0.3s ease;
}

#backdrop .content-box .exit:hover {
  transform: rotate(90deg);
}

#backdrop .content-box .button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  #backdrop .content-box .info {
    font-size: 24px;
    letter-spacing: 0.01em;
    line-height: 1.2;
    margin-bottom: 46px;
  }

  #backdrop .content-box {
    padding: 89px 32px;
  }

  #backdrop .content-box .logo {
    height: 36px;
    width: auto;
    margin-bottom: 20px;
  }

  #backdrop .content-box .button.two {
    margin-bottom: 16px;
  }

  #backdrop .content-box .row {
    margin-bottom: 30px;
  }
}

@media (max-width: 576px) {
  #backdrop {
    overflow: scroll !important;
  }

  #backdrop .content-box {
    padding: 40px 30px;
  }

  #backdrop .content-box .description {
    margin-bottom: 15px;
  }
  #backdrop .content-box .info {
    margin-bottom: 24px;
    letter-spacing: 0em;
    font-weight: 300;
  }
}

@media (max-width: 450px) {
  #backdrop {
    width: 100vw;
  }
}

@media (max-width: 400px) {
  #backdrop .content-box {
    padding-top: 45px;
  }

  #backdrop .content-box .exit {
    top: 32px;
    right: 32px;
  }
}
