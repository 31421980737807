#telegram {
  width: 100%;
  height: max-content;
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 231px;
}

#telegram .column {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  margin-bottom: 64px;
}

#telegram .low {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 10px;
  color: #5C44EC;
}

#telegram .high {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 400;
  color: #5C44EC;
  margin-bottom: 25px;
}

#telegram .disabled-link {
  pointer-events: none;
}

@media (max-width: 768px) {
  #telegram {
    margin-bottom: 136px;
  }
}