#roadmap {
  height: max-content;
  width: 100%;
  background-color: black;
}

#roadmap .gradient {
  width: 100%;
  z-index: 3;
  height: max-content;
  padding-top: 103px;
  background: linear-gradient(180deg, #1D1450 -56%, #000000 100%);
}