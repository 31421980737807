footer {
  padding-top: 228px;
  padding-bottom: 123px;
  background-color: black;
  /* background: linear-gradient(to bottom, #1d1450 -15.46%, #000000 25.96%); */
  height: max-content;
  z-index: 3;
}

#contact {
  position: relative;
  overflow: hidden;
}

footer .footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer .social-media {
  display: flex;
  align-content: center;
  column-gap: 12px;
  color: #fafafc;
  width: max-content;
}

footer .social-media-link {
  z-index: 4;
}

footer .social-media-link:hover {
  color: #5c44ec;
  opacity: 1;
  filter: brightness(0) saturate(100%) invert(30%) sepia(25%) saturate(5853%) hue-rotate(236deg)
    brightness(90%) contrast(106%);
}

footer .logo {
  width: 109px;
  height: 62px;
  margin-bottom: 72px;
}

footer .askUs {
  font-weight: 400;
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: white;
  text-align: center;
}

footer .contact-email {
  color: white;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 109px;
}

footer .divider {
  display: none;
}

@media (max-width: 670px) {
  footer {
    padding-top: 80px;
  }
}

@media (max-width: 768px) {
  footer {
    padding-bottom: 50px;
  }

  footer .divider {
    display: block !important;
    width: 100% !important;
    height: 1px !important;
    background: linear-gradient(
      90deg,
      rgba(52, 149, 255, 1) 0%,
      rgba(92, 68, 236, 1) 50%,
      rgba(187, 93, 238, 1) 100%
    ) !important;
    opacity: 0.5 !important;
    margin-bottom: 31px;
  }
}
