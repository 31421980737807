#header {
  width: 100%;
  height: max-content;
  height: 915px;
  background: #020202;
  position: relative;
}

#header .header-row {
  height: 100%;
  width: auto;
}

#header .header-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  padding-top: 170px;
}

#header button {
  text-transform: uppercase;
  width: 272px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 16px; */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  /* margin-right: 20px; */
  border: none;
}

#header button:hover {
  filter: brightness(1.05);
}

#header .absolute-container {
  position: absolute;
  width: 1120px;
  height: 100%;
  left: 150px;
  top: 0px;
}

#header .content {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#header .logo {
  margin-bottom: 66px;
}

#header p {
  font-weight: 400;
  line-height: 1.6;
  font-size: 20px;
  color: white;
  margin-bottom: 35px;
  letter-spacing: 0.01em;
  margin-bottom: 28px;
  max-width: 368px;
}

#header .vote-button {
  background-color: transparent;
  border: 1px solid #ffaf39;
  color: #ffaf39;
}

#header .vote-button:hover {
  background-color: rgba(255, 175, 57, 0.2);
}

#header h1 {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 300;
  margin-bottom: 79px;
  color: white;
  max-width: 530px;
}

#header img {
  transform: translateX(314px);
}

@media (min-width: 2200px) {
  #header img {
    transform: translateX(520px);
  }
}

@media (max-width: 1800px) {
  #header img {
    transform: translateX(274px);
  }
}

@media (max-width: 1440px) {
  #header img {
    transform: translateX(-74px);
  }
}

@media (max-width: 1200px) {
  #header img {
    transform: translateX(-250px);
  }
}

@media (max-width: 1100px) {
  #header .absolute-container {
    left: 0;
  }

  #header img {
    transform: translateX(-326px);
  }
}

@media (max-width: 670px) {
  #header {
    height: 821px;
  }

  #header img {
    transform: translate(-586px, -93px);
    opacity: 0.6;
  }

  #header .content {
    margin-top: 80px;
  }

  #header .logo {
    margin-bottom: 200px;
  }

  #header h1 {
    font-size: 36px;
    line-height: 1.4;
  }

  #header .header-col {
    align-items: flex-start;
  }
}
