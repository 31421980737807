@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');

html {
  width: 100%;
  height: max-content;
  background-color: black;
  overflow-x: hidden;
}

iframe {
  z-index: 1 !important;
}

.container-xxl {
  max-width: 1440px !important;
}

.container-xl {
  max-width: 1140px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
}

p {
  color: #7e7e7f;
}

h2 {
  font-size: 48px;
}

/* #App {} */

#root {
  width: 100%;
  height: max-content;
  overflow-x: hidden;
}

#faq {
  width: 100%;
  height: max-content;
  padding-top: 177px;
  background-color: black;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Oxygen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
