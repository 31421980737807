#logo-section {
  margin-bottom: 72px;
}

#logo-section svg {
  width: min-content;
}


#roadmap-columns {
  margin-bottom: 100px;
}

#roadmap-columns .title {
  margin-bottom: 27px;
  font-weight: 300;
}

#roadmap-columns .column {
  max-width: 380px;
}

#roadmap-columns .text {
  line-height: 1.6;
  letter-spacing: 0.02em;
  margin-bottom: 50px;
  font-size: 14px;
  font-weight: 400;
}

#stages {
  flex-direction: column;
}

#stages .content {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#step-left {
  text-align: right;
  align-items: flex-end;
}

#step-left .expiration {
  text-align: left;
}

#step-left .content {
  align-items: flex-end;
  
}

#step-right {
  text-align: left;
  align-items: flex-start;
}

#step-right .expiration {
  text-align: right;
}

#step-right .content {
  align-items: flex-start;
}

.stage-row {
  height: max-content;
  width: 100%;
  position: relative;
}

.step-col {
  min-height: 860px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.step-col .image {
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.step-col .step {
  font-size: 42px;
  line-height: 1.8;
  font-weight: 300;
}

.step-col .stage {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.02em;
  font-size: 14px;
  margin-bottom: 20px;
}

.step-col .expiration {
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: white;
  letter-spacing: 0.04em;
  line-height: 1.8;
  padding: 4px 10px;
  background: linear-gradient(270.16deg, #3495FF 0.02%, #5C44EC 43.38%, #BB5DEE 97.16%);
  margin-bottom: 30px;
}

.step-col .heading {
  max-width: 300px;
  margin-bottom: 30px;
  font-size: 32px;
  line-height: 42px;
  color: #FAFAFC !important;
}

.step-col .text {
  color: white;
  font-weight: 400;
  line-height: 1.8;
  font-size: 14px;
  opacity: 0.5;
  letter-spacing: 0.07em;
  margin-bottom: 27px;
}

.step-col .text-orange {
  font-size: 12px;
  text-transform: uppercase;
  color: #FFAF39;
  letter-spacing: 0.8px;
  font-weight: 400;
}

.step-col .bold {
  font-weight: 600;
}

.step-col .c2a {
  padding: 7px 30px;
  background-color: transparent;
  border-radius: 8.7px;
  border: 2px solid #FFAF39;
  color: #FFAF39;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.55px;
  min-width: 125px;
  min-height: 28px;
}

#flare {
  position: absolute;
  right: -65%;
  bottom: -103px;
}

@media (max-width: 768px) {
  .step-col .heading {
    max-width: 250px;
  }

  #logo-section svg {
    width: 300px;
  }

  #roadmap-columns .text {
    margin-bottom: 32px;
    font-weight: 300;
  }

  .step-col .text {
    font-weight: 300;
  }
}

@media (max-width: 991px) {
  #stages {
    padding-top: 300px;
    padding-bottom: 215px;
  }

  #stages .step-col {
    position: unset;
    justify-content: flex-end;
  }

  #stages .content {
    z-index: 2;
  }

  .stage-row {
    position: relative;
  }

  #stages .step-col .image {
    left: 50% !important;
    top: 59px;
    transform: translateX(-50%) !important;
    z-index: 1;
  }

  #stages .step-col .step {
    margin-bottom: 15px;
  }

  #stages .step-col .step {
    margin-bottom: 15px;
  }

  #stages .step-col .expiration {
    margin-bottom: 21px;
  }

  #stages .step-col .heading {
    margin-bottom: 15px;
  }
}
