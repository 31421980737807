@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
#navigation-bar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 7;
  background: rgba(0, 0, 0, 0.6);
}

#navigation-bar .navbar-row {
  height: 72px;
}

#navigation-bar .navbar-col {
  display: flex;
  align-items: center;
  color: white !important;
  opacity: 1;
}

#navigation-bar .navbar-logo-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#navigation-bar .mobile-menu {
  /* width: 100%; */
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  position: relative;
  z-index: 2;
}

#navigation-bar .mobile-menu .logo {
  height: 100%;
  width: auto;
  margin-bottom: 0px;
}

#navigation-bar .mobile-menu .hamburger {
  width: 30px;
  height: 30px;
  background-image: url(/static/media/hamburger.962fdb24.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0px;
  margin: 0;
  border: none !important;
  border-radius: 0;
  background-color: transparent;
}

#navigation-bar .menu-list {
  display: none;
}

.menu-list.collapsed {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  padding-top: 72px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  /* background-color: rgba(94, 43, 43, 0.5); */
}

.anchor:hover {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

.navbar-col a {
  margin-right: 30px;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
  color: white;
  cursor: pointer;
}

.menu-list a {
  text-decoration: none;
  margin-bottom: 28px;
  color: white;
  font-size: 22px;
  font-weight: 500;
}

#navigation-bar select {
  color: white;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

#navigation-bar select option {
  background-color: transparent;
  color: black;
  padding: 10px;
  cursor: pointer;
}

#navigation-bar select option:first-child {
  margin-top: 50px;
}

#navigation-bar select:focus {
  border: none;
  outline: none;
}

@media (max-width: 768px) {
  #navigation-bar select {
    font-size: 22px;
  }
}

#categories {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  background-color: black;
}

#categories .title {
  color: white;
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 0.01em;
  margin-bottom: 125px;
}

#categories .image {
  margin-bottom: 40px;
  height: 66px;
  width: auto;
}

#categories .heading {
  margin-bottom: 30px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

#categories .text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  opacity: 0.5;
  width: 100%;
  max-width: 400px;
}

#categories .column {
  margin-bottom: 123px;
}

/* #categories .flare.one {
  position: absolute;
  left: -37%;
  top: -568px;
}

#categories .flare.one {
  position: absolute;
  left: -37%;
  top: -568px;
} */

@media (max-width: 768px) {
  #categories .column {
    margin-bottom: 32px;
  }

  #categories .title {
    margin-bottom: 70px;
  }

  #categories .flare.two {
    top: 1025px !important;
  }

  #categories .text {
    font-weight: 300;
  }
}

#discover {
  background-color: black;
  /* background: linear-gradient(to top, #1D1450 -4.46%, #000000 8.96%); */
}

#discover h1 {
  color: #fff;
  font-size: 48px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0.01em;
  margin-bottom: 83px;
}

#discover .article {
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  row-gap: 10px;
  margin-bottom: 6px;
  z-index: 3;
}

#discover .article .article-title-container {
  width: 100%;
  min-height: 56px;
  margin-bottom: 30px;

  display: flex;
  align-items: flex-end;
}

#discover .article h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 0;
  vertical-align: bottom;
}

#discover .article .article-img-container {
  margin-bottom: 20px;
  isolation: isolate;
}

#discover .article .article-img {
  mix-blend-mode: color;
}

#discover .article .color-mask {
  width: 100%;
  height: 100%;
  position: relative;
  top: -100%;
  left: 0;
  z-index: 1;
}

#discover .article img {
  width: 100%;
  height: auto;
}

#discover .article p {
  color: #fafafc;
  font-size: 14px;
  line-height: 160%;
  font-weight: 400;
  letter-spacing: 0.02em;
  opacity: 0.5;
  margin-bottom: 40px;
}

#discover .article a {
  color: #5C44EC;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-decoration: none;
}

#discover .article a:hover {
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3);
}

#articles {
  padding-bottom: 247px;
}

@media (max-width: 768px) {
  #articles {
    padding-bottom: 104px;
  }

  #discover h1 {
    margin-bottom: 79px;
  }

  #discover .article p {
    margin-bottom: 20px;
    font-weight: 300;
  }

  #discover .flare {
    top: -1173px !important;
    right: -1134px !important;
    bottom: unset;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}

@media (max-width: 1200px) {
  #discover .article {
    margin-bottom: 85px;
  }
}
#footer-contact {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
}

#footer-contact .column {
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

#footer-contact .column .social-media svg {
  width: 24px;
  height: 24px;
}

#footer-contact .logo {
  width: 144px;
  height: auto;
  margin-bottom: 0px;
}

#footer-contact .column.first a {
  z-index: 3;
}

#footer-contact .texts {
  margin-bottom: 3px;
}

#footer-contact .text {
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.05em;
  font-weight: 500;
  color: white;
}

#footer-contact .link {
  width: -webkit-max-content;
  width: max-content; 
  height: -webkit-max-content; 
  height: max-content; 
  text-decoration: none; 
  z-index: 2; 
  text-align: center;
}

#footer-contact .second {
  color: #5C44EC;
  z-index: 3;
}

@media (max-width: 768px) {
  #footer-contact .logo {
    margin-top: 31px;
    margin-bottom: 28px;
  }

  #footer-contact .column {
    margin-bottom: 20px;
  }

  #footer-contact .second {
    margin-bottom: 56px;
    padding: 0;
    text-align: center;
  }

  #footer-contact .column .social-media svg {
    width: 36px;
    height: 36px;
  }
  
  #footer-contact .column .social-media a {
    z-index: 4;
  }

  #footer-contact .social-media {
    grid-column-gap: 24px;
    -webkit-column-gap: 24px;
            column-gap: 24px;
  }
}
#telegram {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 231px;
}

#telegram .column {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 64px;
}

#telegram .low {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 10px;
  color: #5C44EC;
}

#telegram .high {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 400;
  color: #5C44EC;
  margin-bottom: 25px;
}

#telegram .disabled-link {
  pointer-events: none;
}

@media (max-width: 768px) {
  #telegram {
    margin-bottom: 136px;
  }
}
footer {
  padding-top: 228px;
  padding-bottom: 123px;
  background-color: black;
  /* background: linear-gradient(to bottom, #1d1450 -15.46%, #000000 25.96%); */
  height: -webkit-max-content;
  height: max-content;
  z-index: 3;
}

#contact {
  position: relative;
  overflow: hidden;
}

footer .footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer .social-media {
  display: flex;
  align-content: center;
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
          column-gap: 12px;
  color: #fafafc;
  width: -webkit-max-content;
  width: max-content;
}

footer .social-media-link {
  z-index: 4;
}

footer .social-media-link:hover {
  color: #5c44ec;
  opacity: 1;
  -webkit-filter: brightness(0) saturate(100%) invert(30%) sepia(25%) saturate(5853%) hue-rotate(236deg)
    brightness(90%) contrast(106%);
          filter: brightness(0) saturate(100%) invert(30%) sepia(25%) saturate(5853%) hue-rotate(236deg)
    brightness(90%) contrast(106%);
}

footer .logo {
  width: 109px;
  height: 62px;
  margin-bottom: 72px;
}

footer .askUs {
  font-weight: 400;
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: white;
  text-align: center;
}

footer .contact-email {
  color: white;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 109px;
}

footer .divider {
  display: none;
}

@media (max-width: 670px) {
  footer {
    padding-top: 80px;
  }
}

@media (max-width: 768px) {
  footer {
    padding-bottom: 50px;
  }

  footer .divider {
    display: block !important;
    width: 100% !important;
    height: 1px !important;
    background: linear-gradient(
      90deg,
      rgba(52, 149, 255, 1) 0%,
      rgba(92, 68, 236, 1) 50%,
      rgba(187, 93, 238, 1) 100%
    ) !important;
    opacity: 0.5 !important;
    margin-bottom: 31px;
  }
}

#backdrop {
  width: calc(100vw - 17px);
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  overflow: hidden;
  padding: 15px;
  z-index: 10;
}

@-webkit-keyframes slideDown {
  from {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

#backdrop .content-box {
  padding: 81px 64px;
  min-width: 345px;
  width: 918px;
  height: -webkit-max-content;
  height: max-content;
  background-color: #5c44ec;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  -webkit-animation: 0.3s slideDown ease-out;
          animation: 0.3s slideDown ease-out;
  z-index: 11;
}

#backdrop .content-box .logo {
  margin-bottom: 38px;
  width: 289px;
}

#backdrop .content-box .info {
  color: white;
  font-size: 28px;
  letter-spacing: 0.01em;
  text-align: start;
  line-height: 1.2;
  font-weight: 300;
  max-width: 100% !important;
  margin-bottom: 59px;
}

#backdrop .content-box .row {
  margin-bottom: 48px;
  width: 100%;
}

#backdrop .content-box .description {
  margin-bottom: 18px;
  font-weight: 300;
  letter-spacing: 0.01em;
  color: white;
  font-size: 16px;
  text-align: left !important;
}

#backdrop .numbers {
  font-size: 16px;
  font-weight: 300;
  color: white;
  letter-spacing: 0.04em;
  margin-bottom: 0px;
  text-align: left !important;
}

#backdrop .buttons-wrapper {
  display: flex;
  flex-wrap: wrap-reverse;
}

#backdrop .buttons-wrapper a {
  text-decoration: none;
}

#backdrop .content-box .button {
  border-radius: 0px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  text-transform: uppercase;
  width: 229px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-weight: 700;
  letter-spacing: 0.01em;
  font-size: 16px;
  margin-right: 16px;
  /* max-width: 230px; */
  transition: background-color 0.3s ease;
}

#backdrop .content-box .exit {
  position: absolute;
  right: 33px;
  top: 33px;
  height: 24px;
  width: 24px;
  z-index: 15;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

#backdrop .content-box .exit:hover {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

#backdrop .content-box .button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  #backdrop .content-box .info {
    font-size: 24px;
    letter-spacing: 0.01em;
    line-height: 1.2;
    margin-bottom: 46px;
  }

  #backdrop .content-box {
    padding: 89px 32px;
  }

  #backdrop .content-box .logo {
    height: 36px;
    width: auto;
    margin-bottom: 20px;
  }

  #backdrop .content-box .button.two {
    margin-bottom: 16px;
  }

  #backdrop .content-box .row {
    margin-bottom: 30px;
  }
}

@media (max-width: 576px) {
  #backdrop {
    overflow: scroll !important;
  }

  #backdrop .content-box {
    padding: 40px 30px;
  }

  #backdrop .content-box .description {
    margin-bottom: 15px;
  }
  #backdrop .content-box .info {
    margin-bottom: 24px;
    letter-spacing: 0em;
    font-weight: 300;
  }
}

@media (max-width: 450px) {
  #backdrop {
    width: 100vw;
  }
}

@media (max-width: 400px) {
  #backdrop .content-box {
    padding-top: 45px;
  }

  #backdrop .content-box .exit {
    top: 32px;
    right: 32px;
  }
}

.header-row {
  height: 915px;
}

#content .c2a-button-left {
  background: #ffaf39;
  color: black;
  margin-bottom: 56px;
  font-weight: 600;
}

#content .link {
  text-decoration: none;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
}

#content .c2a-button-right {
  background: rgba(0, 0, 0, 0.7);
  color: #ffaf39;
  font-weight: 600;
  border: 2px solid #ffaf39;
}

#content button {
  min-width: 272px;
  height: 48px;
}

#content .button-wrapper {
  display: flex;
  flex-direction: row;
}

button + button {
  margin-left: 15px;
}

#content hr.hr {
  height: 1px;
  background-color: #fafafc;
  opacity: 0.2;
  margin-bottom: 47px;
}

@media (max-width: 670px) {
  /* #content .text {
    color: white;
    font-size: 20px;
    letter-spacing: 0.01em;
    font-weight: 400;
    max-width: 175px;
  } */
  /* #content .content-box {
    padding: 40px 15px;
  } */

  #content .heading {
    letter-spacing: 0.01em;
    margin-bottom: 50px;
  }

  #content .c2a-button-left {
    margin-bottom: 0px;
  }

  #content .button-wrapper {
    flex-direction: column;
  }

  button+button {
    margin-left: 0px;
    margin-top: 15px;
  }



  /* #content .c2a-button-right {
    color: white;
    border: 2px solid white;
    opacity: 0.8;
  } */
}

#header {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  height: 915px;
  background: #020202;
  position: relative;
}

#header .header-row {
  height: 100%;
  width: auto;
}

#header .header-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  padding-top: 170px;
}

#header button {
  text-transform: uppercase;
  width: 272px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 16px; */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  /* margin-right: 20px; */
  border: none;
}

#header button:hover {
  -webkit-filter: brightness(1.05);
          filter: brightness(1.05);
}

#header .absolute-container {
  position: absolute;
  width: 1120px;
  height: 100%;
  left: 150px;
  top: 0px;
}

#header .content {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#header .logo {
  margin-bottom: 66px;
}

#header p {
  font-weight: 400;
  line-height: 1.6;
  font-size: 20px;
  color: white;
  margin-bottom: 35px;
  letter-spacing: 0.01em;
  margin-bottom: 28px;
  max-width: 368px;
}

#header .vote-button {
  background-color: transparent;
  border: 1px solid #ffaf39;
  color: #ffaf39;
}

#header .vote-button:hover {
  background-color: rgba(255, 175, 57, 0.2);
}

#header h1 {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 300;
  margin-bottom: 79px;
  color: white;
  max-width: 530px;
}

#header img {
  -webkit-transform: translateX(314px);
          transform: translateX(314px);
}

@media (min-width: 2200px) {
  #header img {
    -webkit-transform: translateX(520px);
            transform: translateX(520px);
  }
}

@media (max-width: 1800px) {
  #header img {
    -webkit-transform: translateX(274px);
            transform: translateX(274px);
  }
}

@media (max-width: 1440px) {
  #header img {
    -webkit-transform: translateX(-74px);
            transform: translateX(-74px);
  }
}

@media (max-width: 1200px) {
  #header img {
    -webkit-transform: translateX(-250px);
            transform: translateX(-250px);
  }
}

@media (max-width: 1100px) {
  #header .absolute-container {
    left: 0;
  }

  #header img {
    -webkit-transform: translateX(-326px);
            transform: translateX(-326px);
  }
}

@media (max-width: 670px) {
  #header {
    height: 821px;
  }

  #header img {
    -webkit-transform: translate(-586px, -93px);
            transform: translate(-586px, -93px);
    opacity: 0.6;
  }

  #header .content {
    margin-top: 80px;
  }

  #header .logo {
    margin-bottom: 200px;
  }

  #header h1 {
    font-size: 36px;
    line-height: 1.4;
  }

  #header .header-col {
    align-items: flex-start;
  }
}

#roadmap {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  background-color: black;
}

#roadmap .gradient {
  width: 100%;
  z-index: 3;
  height: -webkit-max-content;
  height: max-content;
  padding-top: 103px;
  background: linear-gradient(180deg, #1D1450 -56%, #000000 100%);
}
#logo-section {
  margin-bottom: 72px;
}

#logo-section svg {
  width: -webkit-min-content;
  width: min-content;
}


#roadmap-columns {
  margin-bottom: 100px;
}

#roadmap-columns .title {
  margin-bottom: 27px;
  font-weight: 300;
}

#roadmap-columns .column {
  max-width: 380px;
}

#roadmap-columns .text {
  line-height: 1.6;
  letter-spacing: 0.02em;
  margin-bottom: 50px;
  font-size: 14px;
  font-weight: 400;
}

#stages {
  flex-direction: column;
}

#stages .content {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#step-left {
  text-align: right;
  align-items: flex-end;
}

#step-left .expiration {
  text-align: left;
}

#step-left .content {
  align-items: flex-end;
  
}

#step-right {
  text-align: left;
  align-items: flex-start;
}

#step-right .expiration {
  text-align: right;
}

#step-right .content {
  align-items: flex-start;
}

.stage-row {
  height: -webkit-max-content;
  height: max-content;
  width: 100%;
  position: relative;
}

.step-col {
  min-height: 860px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.step-col .image {
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.step-col .step {
  font-size: 42px;
  line-height: 1.8;
  font-weight: 300;
}

.step-col .stage {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.02em;
  font-size: 14px;
  margin-bottom: 20px;
}

.step-col .expiration {
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: white;
  letter-spacing: 0.04em;
  line-height: 1.8;
  padding: 4px 10px;
  background: linear-gradient(270.16deg, #3495FF 0.02%, #5C44EC 43.38%, #BB5DEE 97.16%);
  margin-bottom: 30px;
}

.step-col .heading {
  max-width: 300px;
  margin-bottom: 30px;
  font-size: 32px;
  line-height: 42px;
  color: #FAFAFC !important;
}

.step-col .text {
  color: white;
  font-weight: 400;
  line-height: 1.8;
  font-size: 14px;
  opacity: 0.5;
  letter-spacing: 0.07em;
  margin-bottom: 27px;
}

.step-col .text-orange {
  font-size: 12px;
  text-transform: uppercase;
  color: #FFAF39;
  letter-spacing: 0.8px;
  font-weight: 400;
}

.step-col .bold {
  font-weight: 600;
}

.step-col .c2a {
  padding: 7px 30px;
  background-color: transparent;
  border-radius: 8.7px;
  border: 2px solid #FFAF39;
  color: #FFAF39;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.55px;
  min-width: 125px;
  min-height: 28px;
}

#flare {
  position: absolute;
  right: -65%;
  bottom: -103px;
}

@media (max-width: 768px) {
  .step-col .heading {
    max-width: 250px;
  }

  #logo-section svg {
    width: 300px;
  }

  #roadmap-columns .text {
    margin-bottom: 32px;
    font-weight: 300;
  }

  .step-col .text {
    font-weight: 300;
  }
}

@media (max-width: 991px) {
  #stages {
    padding-top: 300px;
    padding-bottom: 215px;
  }

  #stages .step-col {
    position: unset;
    justify-content: flex-end;
  }

  #stages .content {
    z-index: 2;
  }

  .stage-row {
    position: relative;
  }

  #stages .step-col .image {
    left: 50% !important;
    top: 59px;
    -webkit-transform: translateX(-50%) !important;
            transform: translateX(-50%) !important;
    z-index: 1;
  }

  #stages .step-col .step {
    margin-bottom: 15px;
  }

  #stages .step-col .step {
    margin-bottom: 15px;
  }

  #stages .step-col .expiration {
    margin-bottom: 21px;
  }

  #stages .step-col .heading {
    margin-bottom: 15px;
  }
}

#tos {
  padding: 200px 0px;
  color: #fff;
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  background: black;
}

#tos h1 {
  font-size: 48px;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: 0.01em;
  margin-bottom: 50px;
}

#tos h3 {
  font-size: 28px;
  line-height: 160%;
  letter-spacing: 0.01em;
  margin-bottom: 50px;
}

#tos ol {
  color: #fff;
  opacity: 0.5;
  font-size: 16px;
  line-height: 1.9;
  letter-spacing: 0.01em;
  margin-bottom: 40px;
  padding-left: 17px;
}

#tos ol li {
  font-weight: 400;
}

#tos a.show-terms {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  color: #FFAF39;
  text-decoration: none;
  background: none;
  z-index: 5;
  border: 2px solid #FFAF39;
  padding: 10px 50px;
  transition: background-color 0.2s ease;
}

#tos a.show-terms:hover {
  background-color: rgba(255, 175, 57, 0.2);
}

/* #tos button.show-terms:hover {
  color: #000;
  background-color: #5C44ec;
} */

#tos #bullet-points {
  border-top: 1px solid linear-gradient(90deg, rgba(52,149,255,1) 0%, rgba(92,68,236,1) 50%, rgba(187,93,238,1) 100%);
}

#tos .gradient {
  margin-bottom: 60px;
  background: rgb(52,149,255);
  background: linear-gradient(90deg, rgba(52,149,255,1) 0%, rgba(92,68,236,1) 50%, rgba(187,93,238,1) 100%);
  width: 100%;
  height: 1px;
}

#bullet-points {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #FAFAFC;
  opacity: 1;
}

#tos .bullet-point {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  margin-bottom: 40px;
}

#tos .bullet-point .show-github {
  margin-top: 20px;
  text-decoration: none;
  color: #5C44EC;
  /* color: #474747; */
  opacity: 1 !important;
  z-index: 4;
  /* pointer-events: none; */
}

#tos .bullet-point .show-doc {
  margin-top: 7px;
  text-decoration: none;
  color: #5C44EC;
  /* color: #474747; */
  opacity: 1 !important;
  z-index: 4;
  /* pointer-events */
}

#tos .bullet-point .show-github:hover {
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3);
}

#tos .bullet-point .show-doc:hover {
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3);
}

#tos .bullet-point p {
  margin-bottom: 0px;
  font-size: 14px;
  color: white;
  font-weight: 400;
  opacity: 0.5;
  letter-spacing: 1px;
  line-height: 20px;
  max-width: 265px;
}

#tos .lines {
  position: absolute;
  right: -877px;
  top: -144px;
}

#tos #main-row {
  margin-bottom: 92px;
  z-index: 3;
}

@media (max-width: 768px) {
  #tos h1 {
    margin-bottom: 40px;
  }

  #tos h3 {
    margin-bottom: 20px;
  }

  #tos ol {
    margin-bottom: 40px;
  }

  #tos .bullet-point p {
    font-weight: 300;
  }
  
  #tos ol li {
    font-weight: 300;
  }

  #tos .gradient {
    opacity: 0.4;
    /* display: none; */
  }

  /* #tos #bullet-points {
    display: none;
  } */

  #tos {
    padding-top: 371px;
    padding-bottom: 200px;
  }

  #tos .lines {
    top: -268px;
    right: -940px;
  }
}

@media (max-width: 1440px) and (min-width: 769px) {
  #tos .lines {
    position: absolute;
    right: calc(-1104px + 30vw);
    top: -144px;
  }
}
#faq-section {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding-bottom: 353px;
  /* background: linear-gradient(to top, #1D1450 -4.46%, #000000 8.96%); */
  position: relative;
  z-index: 3;
}

#faq-section .title {
  font-weight: 300;
  font-size: 48px;
  line-height: 1.2;
  letter-spacing: 0.01em;
  margin-bottom: 100px;
}

#faq-section .title::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

#faq-section .answer {
  margin-bottom: 0px;
  color: white;
  opacity: 0.5;
}

#faq-section .accordion {
  background-color: transparent;
}

/* #faq-section .answer:last-child {
  margin-bottom: 0px;
} */

#faq-section .accordion-item .accordion-button {
  background-color: transparent;
  color: white;
  padding-bottom: 18px !important;
  padding: 0px;
  padding-top: 43px !important;
  border-bottom: 1px solid #5c44ec;
  z-index: 3;
  opacity: 0.8;
}

#faq-section .accordion-item {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border: none;
  z-index: 3;
}

#faq-section .accordion-header {
  z-index: 3;
  background-color: black;
}

#faq-section .accordion-body {
  background-color: black;
  padding: 0px;
  padding-bottom: 56px !important;
  padding-top: 60px !important;
  display: flex;
  border-bottom: 1px solid #5c44ec;
}

#faq-section .accordion-button {
  text-transform: capitalize;
  font-size: 20px;
  letter-spacing: 0.06em;
  font-weight: 400;
  transition: font-size 0.2s ease-out;
}

#faq-section .accordion-button:hover {
  opacity: 0.9;
}

#faq-section .accordion-button[aria-expanded='true'] {
  font-size: 22px;
  opacity: 1;
  border-color: transparent !important;
}

#faq-section .accordion-button:focus {
  box-shadow: none !important;
}

#faq-section .accordion-button::after {
  background-image: url(/static/media/arrow-down.a06958b2.svg) !important;
}

.accordion-button:not(.collapsed)::after {
  -webkit-transform: scale(1.5) rotate(180deg) translate(3px, 5px) !important;
          transform: scale(1.5) rotate(180deg) translate(3px, 5px) !important;
}

#send-application {
  z-index: 6;
}

@media (max-width: 991px) {
  #faq-section .answer {
    margin-bottom: 25px;
  }

  #faq-section .accordion-body {
    padding-bottom: 71px !important;
    padding-top: 16px !important;
  }

  #faq-section .container-xl {
    padding: 0px;
  }

  #faq-section .accordion-item .accordion-button {
    padding: 35px 0px !important;
    align-items: center;
  }

  .accordion-button.collapsed::after {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

@media (max-width: 768px) {
  #faq-section {
    padding-top: 263px;
    padding-bottom: 203px;
  }

  #faq-section .title {
    margin-bottom: 79px;
  }
}

/* @media (max-width: 576px) {
} */

#logo-and-graphic {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  position: relative;
}

#logo-and-graphic .logo {
  margin-bottom: 253px;
}

#logo-and-graphic .wave {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 72px;
}

@media (max-width: 1200px) {
  #logo-and-graphic .wave {
    -webkit-transform: none;
            transform: none;
    left: -657px;
  }
}

@media(max-width: 768px) {
  #logo-and-graphic .skey-row {
    display: none;
  }

  #logo-and-graphic .wave {
    -webkit-transform: none;
            transform: none;
    right: -907px;
    top: -92px;
    left: unset;
  }
}

html {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  background-color: black;
  overflow-x: hidden;
}

iframe {
  z-index: 1 !important;
}

.container-xxl {
  max-width: 1440px !important;
}

.container-xl {
  max-width: 1140px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
}

p {
  color: #7e7e7f;
}

h2 {
  font-size: 48px;
}

/* #App {} */

#root {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  overflow-x: hidden;
}

#faq {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding-top: 177px;
  background-color: black;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Oxygen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

