#logo-and-graphic {
  width: 100%;
  height: max-content;
  position: relative;
}

#logo-and-graphic .logo {
  margin-bottom: 253px;
}

#logo-and-graphic .wave {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 72px;
}

@media (max-width: 1200px) {
  #logo-and-graphic .wave {
    transform: none;
    left: -657px;
  }
}

@media(max-width: 768px) {
  #logo-and-graphic .skey-row {
    display: none;
  }

  #logo-and-graphic .wave {
    transform: none;
    right: -907px;
    top: -92px;
    left: unset;
  }
}
