.header-row {
  height: 915px;
}

#content .c2a-button-left {
  background: #ffaf39;
  color: black;
  margin-bottom: 56px;
  font-weight: 600;
}

#content .link {
  text-decoration: none;
  width: max-content;
  height: max-content;
}

#content .c2a-button-right {
  background: rgba(0, 0, 0, 0.7);
  color: #ffaf39;
  font-weight: 600;
  border: 2px solid #ffaf39;
}

#content button {
  min-width: 272px;
  height: 48px;
}

#content .button-wrapper {
  display: flex;
  flex-direction: row;
}

button + button {
  margin-left: 15px;
}

#content hr.hr {
  height: 1px;
  background-color: #fafafc;
  opacity: 0.2;
  margin-bottom: 47px;
}

@media (max-width: 670px) {
  /* #content .text {
    color: white;
    font-size: 20px;
    letter-spacing: 0.01em;
    font-weight: 400;
    max-width: 175px;
  } */
  /* #content .content-box {
    padding: 40px 15px;
  } */

  #content .heading {
    letter-spacing: 0.01em;
    margin-bottom: 50px;
  }

  #content .c2a-button-left {
    margin-bottom: 0px;
  }

  #content .button-wrapper {
    flex-direction: column;
  }

  button+button {
    margin-left: 0px;
    margin-top: 15px;
  }



  /* #content .c2a-button-right {
    color: white;
    border: 2px solid white;
    opacity: 0.8;
  } */
}
