#tos {
  padding: 200px 0px;
  color: #fff;
  width: 100%;
  height: max-content;
  background: black;
}

#tos h1 {
  font-size: 48px;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: 0.01em;
  margin-bottom: 50px;
}

#tos h3 {
  font-size: 28px;
  line-height: 160%;
  letter-spacing: 0.01em;
  margin-bottom: 50px;
}

#tos ol {
  color: #fff;
  opacity: 0.5;
  font-size: 16px;
  line-height: 1.9;
  letter-spacing: 0.01em;
  margin-bottom: 40px;
  padding-left: 17px;
}

#tos ol li {
  font-weight: 400;
}

#tos a.show-terms {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  color: #FFAF39;
  text-decoration: none;
  background: none;
  z-index: 5;
  border: 2px solid #FFAF39;
  padding: 10px 50px;
  transition: background-color 0.2s ease;
}

#tos a.show-terms:hover {
  background-color: rgba(255, 175, 57, 0.2);
}

/* #tos button.show-terms:hover {
  color: #000;
  background-color: #5C44ec;
} */

#tos #bullet-points {
  border-top: 1px solid linear-gradient(90deg, rgba(52,149,255,1) 0%, rgba(92,68,236,1) 50%, rgba(187,93,238,1) 100%);
}

#tos .gradient {
  margin-bottom: 60px;
  background: rgb(52,149,255);
  background: linear-gradient(90deg, rgba(52,149,255,1) 0%, rgba(92,68,236,1) 50%, rgba(187,93,238,1) 100%);
  width: 100%;
  height: 1px;
}

#bullet-points {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #FAFAFC;
  opacity: 1;
}

#tos .bullet-point {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 30px;
  margin-bottom: 40px;
}

#tos .bullet-point .show-github {
  margin-top: 20px;
  text-decoration: none;
  color: #5C44EC;
  /* color: #474747; */
  opacity: 1 !important;
  z-index: 4;
  /* pointer-events: none; */
}

#tos .bullet-point .show-doc {
  margin-top: 7px;
  text-decoration: none;
  color: #5C44EC;
  /* color: #474747; */
  opacity: 1 !important;
  z-index: 4;
  /* pointer-events */
}

#tos .bullet-point .show-github:hover {
  filter: brightness(1.3);
}

#tos .bullet-point .show-doc:hover {
  filter: brightness(1.3);
}

#tos .bullet-point p {
  margin-bottom: 0px;
  font-size: 14px;
  color: white;
  font-weight: 400;
  opacity: 0.5;
  letter-spacing: 1px;
  line-height: 20px;
  max-width: 265px;
}

#tos .lines {
  position: absolute;
  right: -877px;
  top: -144px;
}

#tos #main-row {
  margin-bottom: 92px;
  z-index: 3;
}

@media (max-width: 768px) {
  #tos h1 {
    margin-bottom: 40px;
  }

  #tos h3 {
    margin-bottom: 20px;
  }

  #tos ol {
    margin-bottom: 40px;
  }

  #tos .bullet-point p {
    font-weight: 300;
  }
  
  #tos ol li {
    font-weight: 300;
  }

  #tos .gradient {
    opacity: 0.4;
    /* display: none; */
  }

  /* #tos #bullet-points {
    display: none;
  } */

  #tos {
    padding-top: 371px;
    padding-bottom: 200px;
  }

  #tos .lines {
    top: -268px;
    right: -940px;
  }
}

@media (max-width: 1440px) and (min-width: 769px) {
  #tos .lines {
    position: absolute;
    right: calc(-1104px + 30vw);
    top: -144px;
  }
}