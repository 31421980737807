#footer-contact {
  width: 100%;
  height: max-content;
}

#footer-contact .column {
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

#footer-contact .column .social-media svg {
  width: 24px;
  height: 24px;
}

#footer-contact .logo {
  width: 144px;
  height: auto;
  margin-bottom: 0px;
}

#footer-contact .column.first a {
  z-index: 3;
}

#footer-contact .texts {
  margin-bottom: 3px;
}

#footer-contact .text {
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.05em;
  font-weight: 500;
  color: white;
}

#footer-contact .link {
  width: max-content; 
  height: max-content; 
  text-decoration: none; 
  z-index: 2; 
  text-align: center;
}

#footer-contact .second {
  color: #5C44EC;
  z-index: 3;
}

@media (max-width: 768px) {
  #footer-contact .logo {
    margin-top: 31px;
    margin-bottom: 28px;
  }

  #footer-contact .column {
    margin-bottom: 20px;
  }

  #footer-contact .second {
    margin-bottom: 56px;
    padding: 0;
    text-align: center;
  }

  #footer-contact .column .social-media svg {
    width: 36px;
    height: 36px;
  }
  
  #footer-contact .column .social-media a {
    z-index: 4;
  }

  #footer-contact .social-media {
    column-gap: 24px;
  }
}